// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-decision-tsx": () => import("./../../../src/pages/decision.tsx" /* webpackChunkName: "component---src-pages-decision-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-list-tsx": () => import("./../../../src/pages/my_list.tsx" /* webpackChunkName: "component---src-pages-my-list-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-validations-tsx": () => import("./../../../src/pages/validations.tsx" /* webpackChunkName: "component---src-pages-validations-tsx" */),
  "component---src-templates-product-route-js": () => import("./../../../src/templates/productRoute.js" /* webpackChunkName: "component---src-templates-product-route-js" */)
}

